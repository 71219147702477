.repush-btn-div{
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem;
  }

  .formControl{
    width: 100%;
  }

  .padding-2{
    padding: 2rem;
  }

  .flex-between{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ql-container {
    height: auto !important;
  }

  .ql-editor{
    min-height: 100vh !important;
    overflow: hidden;
    overflow-y: scroll;
  }

  .mx-2{
    margin-right: 5px;
    margin-left: 5px;
  }

  .bg-white{
    background-color: #ffffff;
  }